import styled from 'styled-components';
import { shade } from 'polished'
import ReactPlayer from "react-player/lazy";

import Colors from '../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CartContainer = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 10px 20px;
    background-color: ${Colors.borders};
    /* box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7); */

    h1 {
        padding: 10px;
        font-size: 40px;
        line-height: 119%;
        font-weight: bold;
        color: ${Colors.green};
    }
`;

export const SessaoContainer = styled.div`
    margin-top: 54px;
    width: 100%;
    display: flex;
    color: ${Colors.white};
    font-family: "Roboto", sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const PerfilPessoal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    h1 {
        font-size: 40px;
        line-height: 119%;
        font-weight: bold;
        color: ${Colors.green};
    }
`

export const TituloContainer = styled.div`
    color: ${Colors.secondary};
    display: flex;
    font-weight: 700;
    font-size: 35px;
    text-align: center;

    b {
        color: ${Colors.green};
    }
`

export const Content = styled.div`
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DescricaoContainer = styled.text`
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin-right: 23px;
        color: ${Colors.text};
        text-align: right;
        font-size: 20px;
    }
`

export const ButtonContainer = styled.button`
    cursor: pointer;
    border-radius: 5px;
    margin-left: 23px;
    padding: 23px;
    width: 30%;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "Roboto Slab", sans-serif;
    font-weight: bold;
    background: ${Colors.green};
    color: ${Colors.black};
    transition: background-color 0.2s;

    :hover {
        background: ${shade(0.2, Colors.green)};
    }

`

export const ImgContainer = styled.image`
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        text-align: center;
        margin-top: 45px;
        margin-bottom: 45px;
        border-radius: 10px;
        box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
    }
`

export const ContainerVideo = styled.div`
    display: flex;
    width: 94%;
    margin-top: 45px;
    align-items: center;
    justify-content: center;
`

export const ReactPlayers = styled(ReactPlayer)`
    background-color: ${Colors.shapePrice};
    box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.7);
    width: 80% !important;
    height: 580px !important;
    border-radius: 5px;
    margin: 5px;
`;

