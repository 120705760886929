import React from 'react'

import Desenvolvimento from '../../assets/images/desenvolvimento.svg'
import Esporte from '../../assets/images/esporte.svg'
import Leitura from '../../assets/images/leitura.svg'
import Tecnologia from '../../assets/images/tecnologia.svg'

import {
    Hobs,
    Container,
    ContentCursos,
    Title,
    Corpo
} from './styles'

const HobsPessoal: React.FC = () => {
    return (
        <>
            <Hobs>
                <h1>Hobs Pessoal</h1>
            </Hobs>
            <Container>
                <ContentCursos>
                    <Title>
                        <strong>Desenvolvimento</strong>
                        <span>01</span>
                    </Title>
                    <Corpo>
                        {/* <h3>Start</h3> */}
                        {/* <h4>Sua jornada começa aqui.</h4> */}
                        <img src={Desenvolvimento} alt=""/>
                        <p>Sou apaixonado por tecnologia me apaixonei
                            pelo mundo do desenvolvimento. Por isso estou praticando,
                            estudando e aperfeiçando as técnicas.</p>
                    </Corpo>
                </ContentCursos>

                <ContentCursos>
                    <Title>
                        <strong>Esportes</strong>
                        <span>02</span>
                    </Title>
                    <Corpo>
                        {/* <h3>Start</h3> */}
                        {/* <h4>Sua jornada começa aqui.</h4> */}
                        <img src={Esporte} alt=""/>
                        <p>Sou fã e praticante de esportes em geral,
                            tais como: Jiu-Jitsu, Karate,
                            Academia de Musculação, Futebol e Voleibol.</p>
                    </Corpo>
                </ContentCursos>

                <ContentCursos>
                    <Title>
                        <strong>Livros</strong>
                        <span>03</span>
                    </Title>
                    <Corpo>
                        {/* <h3>Start</h3> */}
                        {/* <h4>Sua jornada começa aqui.</h4> */}
                        <img src={Leitura} alt=""/>
                        <p>Tenho uma pequena coleção de livros de vários gêneros.
                            Ler é viajar sem sair do lugar ou gastar dinheiro.</p>
                    </Corpo>
                </ContentCursos>

                <ContentCursos>
                    <Title>
                        <strong>Novas Tecnologias</strong>
                        <span>04</span>
                    </Title>
                    <Corpo>
                        {/* <h3>Start</h3> */}
                        {/* <h4>Sua jornada começa aqui.</h4> */}
                        <img src={Tecnologia} alt=""/>
                        <p>Estou sempre em busca de novos conhecimentos e desafios,
                            principalmente quando se trata na área
                            de tecnologia e desenvolvimento software.</p>
                    </Corpo>
                </ContentCursos>
            </Container>
        </>
    )
}

export default HobsPessoal
