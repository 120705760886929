import styled from 'styled-components'

import Colors from '../../util/colors'

export const Container = styled.div`
    background: ${Colors.shapePrice};
    width: 100%;
    border-radius: 7px;
    padding: 10px !important;
    max-width: 1120px;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
`

export const ContatoTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 20px 0px;

    h1 {
        color: ${Colors.green}
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 20px;
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 50px;

    h2 {
        margin-bottom: 44px;
    }

    p {
        width: 429px;
        height: 142px;
        font-size: 16px;
        color: ${Colors.secondary};
        font-weight: 500;
    }

    a {
        font-weight: 500;
        color: ${Colors.green};
        text-decoration: none;
        margin-top: 10px;
    }
`

export const ContentContato = styled.div`
    display: flex;
    flex-direction: column;
`
