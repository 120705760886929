import styled from 'styled-components'
import Colors from '../../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: ${Colors.background};

    img {
        width: 500px;
        height: 500px;
    }
`

export const ConatainerText = styled.text`
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    h1 {
        font-size: 50px;
        color: ${Colors.green}
    }

    h2 {
        font-size: 35px;
        color: ${Colors.green}
    }
`
