import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Signin from "../pages/Signin";
import SigninCursos from "../pages/SigninCursos";
import Contato from "../pages/Contato";
import GaleriaVideo from "../pages/GaleriaVideo";
import Portfolio from "../pages/Portfolio";
import Projetos from "../pages/Projetos";

import SamBarber from "../../src/components/ProjetosCard/SamBarber";
import SamMecanica from "../../src/components/ProjetosCard/SamMecanica";
import ShareFile from "../../src/components/ProjetosCard/ShareFile";
import SamChat from "../../src/components/ProjetosCard/SamChat";
import SamBell from "../../src/components/ProjetosCard/SamBell";

import DireitoAutorais from "../pages/DireitoAutoraris";
import SobreDesenvolvedor from "../pages/SobreDesenvolvedor";

const Routes: React.FC = () => {
    return (
        <>
            <Switch>
                <Route path="/" exact={true} component={Dashboard} />

                <Route path="/signin" component={Signin} />
                <Route path="/signincursos" component={SigninCursos} />
                <Route path="/contato" component={Contato} />
                <Route path="/galeriavideo" component={GaleriaVideo} />
                <Route path="/portfolio" component={Portfolio} />
                <Route path="/projetos" component={Projetos} />

                <Route path="/sambarberpage" component={SamBarber} />
                <Route path="/sammecanicapage" component={SamMecanica} />
                <Route path="/sharefilepage" component={ShareFile} />
                <Route path="/samchatpage" component={SamChat} />
                <Route path="/sambell" component={SamBell} />

                <Route path="/direitoautoraris" component={DireitoAutorais} />
                <Route
                    path="/sobredesenvolvedor"
                    component={SobreDesenvolvedor}
                />
            </Switch>
        </>
    );
};

export default Routes;
