import styled, { css } from 'styled-components'

import Colors from '../../../util/colors'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
}

export const Container = styled.div<ContainerProps>`
  background: ${Colors.background} 0% 0% no-repeat padding-box;
  border-radius: 7px;
  margin-bottom: 14px;
  display: flex;

  border: 2px solid ${Colors.background};
  color: ${Colors.placeholder};

  ${(props) => props.isFocused && css`
    border: 2px solid ${Colors.green};
    color: ${Colors.green};
  `}

  textarea {
    resize: none;
    border: 0;
    color: ${Colors.white};
    background: transparent;
    font-size: 14px;
    padding: 20px 20px 20px 15px;
    width: 448px;
    height: 128px;

    &::placeholder {
        color: ${Colors.placeholder};
    }
  }

  svg {
    color: ${Colors.placeholder};
    margin: 20px 0 0 16px;

    ${(props) => props.isFocused && css`
        color:  ${Colors.green};
    `}

    ${(props) => props.isFilled && css`
        color:  ${Colors.green};
    `}
  }
`
