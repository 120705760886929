import styled from 'styled-components'

import Colors from '../../util/colors'

export const Container = styled.div`
    background-color: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 10px !important;
    max-width: 1120px;
    margin: 0 auto;
    margin-bottom: 50px;

    img {
        width: 450px;
        height: 450px;
        margin-left: 40px;
    }
`

export const DashboardInfoPessoal = styled.text`
    margin-left: 50px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }
`

export const InfoImage = styled.div`
    align-self: center;
`

export const InfoPessoal = styled.div`
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    h1 {
        font-size: 20px;
        color: ${Colors.green};
    }

    h2 {
        font-size: 30px;
        margin-left: 10px;
    }
`

export const Info = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    h1 {
        color: ${Colors.red};
        font-size: 40px;
    }

    p {
        margin-top: 70px;
        text-align: center;
    }
`
