import React from 'react'

import Footer from '../Footer'

import ChatComercial from '../../assets/portfolio/whatsappclone.png'
import Whatsapp from '../../assets/portfolio/whatsapp.png'
import SamChat from '../../assets/portfolio/samchat.png'
import Twitter from '../../assets/portfolio/twitter.png'
import Hotmail from '../../assets/portfolio/hotmail.png'
import Gmail from '../../assets/portfolio/gmail.png'
import Task from '../../assets/portfolio/task.png'
import CampoMinado from '../../assets/portfolio/campominado.png'
import Calculadora from '../../assets/portfolio/calculadora.png'
import BancoMartins from '../../assets/portfolio/bancomartins.gif'
import AgendaContabil from '../../assets/portfolio/agendacontabil.png'
import GitExplorer from '../../assets/portfolio/gitexplorer.png'
import GoogleClone from '../../assets/portfolio/google-clone.png'
import SpotifyClone from '../../assets/portfolio/spotify-clone.png'
import FacebookClone from '../../assets/portfolio/facebook-clone.png'
import DiscordClone from '../../assets/portfolio/discord.png'
import SamDeliviry from '../../assets/portfolio/samdeliviry.png'
import iMessageClone from '../../assets/portfolio/imessage.png'

import { FaCode, FaGithub } from "react-icons/fa";

import {
    Container,
    ProjetoContainer,
    CardContainer,
    InfoContainer,
    Info,
    AcessaProjeto,
} from './styles'

const PortfolioCard: React.FC = () => {
    return (
        <Container>

            {/* PROJETO IMESSAGE CLONE */}
            <ProjetoContainer>
                <img src={iMessageClone} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>iMessage Apple Clone </h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/imessage-clone'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                    {/* <AcessaProjeto>
                        <FaCode size={23} />
                        <a
                            href='https://nostalgic-leakey-8781ab.netlify.app/'
                        >Link do Deploy</a>
                    </AcessaProjeto> */}
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO DISCORD CLONE */}
            <ProjetoContainer>
                <img src={DiscordClone} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Discord Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/discord-clone'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaCode size={23} />
                        <a
                            href='https://vigilant-joliot-5e0c6e.netlify.app/'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Link do Deploy</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO SAM DELIVIRY */}
            <ProjetoContainer>
                <img src={SamDeliviry} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Sam Deliviry</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/samdeliviry'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO FACEBOOK CLONE */}
            <ProjetoContainer>
                <img src={FacebookClone} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Facebook Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/sporty-clone'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaCode size={23} />
                        <a
                            href='https://nostalgic-leakey-8781ab.netlify.app/'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Link do Deploy</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO SPOTIFY CLONE */}
            <ProjetoContainer>
                <img src={SpotifyClone} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Spotify Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/sporty-clone'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO NAVEGADOR GOOGLE CLONE */}
            <ProjetoContainer>
                <img src={GoogleClone} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Navegador Google Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/google-clone'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaCode size={23} />
                        <a
                            href='https://dazzling-hamilton-497b5d.netlify.app/'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Link do Deploy</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO WHATSAPP CLONE */}
            <ProjetoContainer>
                <img src={ChatComercial} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Chat Comercial</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Este projeto foi desenvolvido no decorrer, dos modulos dos meus cursos,
                            com o propósito de aumentar o conhecimento e enriquecer meu currículo,
                            para que posso estar preparado para o mercado de trabalho.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/chatcomercial'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO AGENDAMENTO DE BARBEARIA */}
            <ProjetoContainer>
                <img src={Whatsapp} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>WhatsApp Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Este projeto foi desenvolvido em meus estudos, com o propósito de aprender e
                        aumentar mais meus conhecimentos a montar um layout responsivo utilizando
                            o Styled-Components(React).</p>
                    </Info>

                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/clone-whatsapp-web'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>


            {/* PROJETO CONTROLE MECANICO */}
            <ProjetoContainer>
                <img src={SamChat} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Discord Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Este projeto foi desenvolvido no decorrer do meu curso,
                            clone do Discord foi criado com o aplicativo Create React App.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/samchat'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={Twitter} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Twitter Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Este projeto foi desenvolvido em meus estudos, com o propósito de aprender e aumentar
                        mais meus conhecimentos a montar um layout responsivo utilizando o Styled-Components(React).</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/clone-twitter'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={Hotmail} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Hotmail Login Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Este tela de login foi desenvolvido em meus estudos, com o propósito de aprender e
                        aumentar mais meus conhecimentos a montar um layout utilizando a biblioteca bootstrap.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/clone-entrar-hotmail'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={Gmail} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Gmail Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Este projeto foi desenvolvido em meus estudos, com o propósito de aprender e
                        aumentar mais meus conhecimentos a montar um layout responsivo utilizando TypeScript.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/Clone-Imbox-Gmail'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={Task} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Agenda de Tarefas</h1>
                    </InfoContainer>
                    <Info>
                        <p>Agenda de Tarefas, app para celular android e IOS,
                        algumas telas que foi feito no processo do desenvolvimento.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/agendadetarefas'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={CampoMinado} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Jogo Campo Minado</h1>
                    </InfoContainer>
                    <Info>
                        <p>Jogo Campo Minado desenvolvido em react-native,
                        para instalar as dependências node-modules basta roda o comando YARN ou NPM INSTALL</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/campominado'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={Calculadora} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Calculadora</h1>
                    </InfoContainer>
                    <Info>
                        <p>Projeto Calculadora foi desenvolvido em react-native,
                        para instalar o node-modules (dependências) roda o comando YARN ou NPM INSTALL</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/calculadora'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={BancoMartins} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Banco Martins Clone</h1>
                    </InfoContainer>
                    <Info>
                        <p>Layout responsivo do Banco Martins usando Styled-Components(React) e Flexbox(css).
                        Este projeto foi desenvolvido no decorrer do meu curso.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/bancomartins'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={AgendaContabil} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Agenda Contabil - IONIC 4</h1>
                    </InfoContainer>
                    <Info>
                        <p>Agenda Contábil desenvolvido em IONIC, app para celular android e IOS, algumas telas que foi feito no processo do meus estudo.
                        O projeto fez parte, quando iniciei com o desenvolvimento hibrido para duas plataforma.</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/agendacontabil'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={GitExplorer} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>Git Explorer</h1>
                    </InfoContainer>
                    <Info>
                        <p>Aplicação busca informações no github utilizando a api do github,
                        foi desenvolvido no decorrer do estudo com programação ReactJS,
                            React-Native, NodeJS. Executar a aplicação: yarn start</p>
                    </Info>
                    <AcessaProjeto>
                        <FaGithub size={23} />
                        <a
                            href='https://github.com/SilvaneiMartins/github_explorer'
                            target="_blank"
                            rel="noopener noreferrer"
                        >Acessar Repositório</a>
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            <Footer />
        </Container>
    )
}

export default PortfolioCard
