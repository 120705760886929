import styled from 'styled-components';
import ReactPlayer from "react-player/lazy";

import Colors from '../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerVideo = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: ${Colors.shape};
`

export const ReactPlayers = styled(ReactPlayer)`
    background-color: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 580px;
    border-radius: 5px;
    margin: 5px;
`;
