import React from 'react';

import { Container } from './styles';

const Contato: React.FC = () => {
    return (
        <Container>
            <h1>Contato</h1>
        </Container>
    );
}

export default Contato;