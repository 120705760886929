import styled, { css } from 'styled-components'

import Colors from '../../../util/colors'

interface ContainerProps {
    isFocused: boolean
    isFilled: boolean
}

export const Container = styled.div<ContainerProps>`
   background: ${Colors.background};
   border-radius: 7px;
   padding: 13px;
   width: 100%;

   border: 2px solid ${Colors.background};
   color: ${Colors.placeholder};

   display: flex;
   align-items: center;

   & + div {
      margin-top: 5px;
   }

   ${props => props.isFocused && css`
      color: ${Colors.green};
      border-color: ${Colors.green};
   `}

   ${props => props.isFilled && css`
      color: ${Colors.green};
   `}

   input {
      flex: 1;
      width: 100%;
      border: 0;
      outline-width: 0;
      background: transparent;
      color: ${Colors.white};

      &::placeholder {
         color: ${Colors.placeholder};
      }
   }

   svg {
      margin-right: 10px;
   }
`
