import styled from 'styled-components'

import Colors from '../../util/colors'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 30px;
    padding: 40px 0 60px 0;
    background: ${Colors.background};

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`

export const Content = styled.div`
    padding: 0 !important;
    display: grid;
    grid-template-columns: 184px 160px 1fr;
    justify-items: flex-end;
    grid-row-gap: 32px;
    grid-column-gap: 40px;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    ul {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        grid-area: 1 / 3;
        grid-row-start: 1;
        grid-row-end: auto;
        grid-column-start: 3;
        grid-column-end: auto;
        list-style: outside none none;

        li {
            margin-right: 24px;

            a {
                font-size: 14px;
                line-height: 16px;
                color: ${Colors.green};
                -webkit-text-decoration: none;
                text-decoration: none;
            }
        }
    }
`

export const ButtonRedSocial = styled.div`
    display: grid;
    grid-template-columns: repeat(4,40px);
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-self: flex-start;
    grid-area: 2 / 1;
    grid-row-start: 2;
    grid-row-end: auto;
    grid-column-start: 1;
    grid-column-end: auto;

    list-style: outside none none;

    li {
        background: ${Colors.shape};
        width: 40px;
        height: 40px;
        border-radius: 5px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        a {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
            height: 100%;

            svg {
                width: 35x;
                height: 35px;
                border-radius: 3px;
            }
        }

        :hover{
            background: ${Colors.borders}
        }
    }
`

export const LinkEmail = styled.div`
    margin-top: 10px;
    margin-left: 8px;

    a {
        margin-left: 115px;
        color: ${Colors.green};
        font-weight: normal;
        font-size: 16px;
        line-height: 1;
        text-decoration: none;
        align-self: center;
        grid-area: 2 / 2;
        grid-row-start: 2;
        grid-row-end: auto;
        grid-column-start: 2;
        grid-column-end: auto;
    }

`

export const LogoFooter = styled.div`
    svg {
        justify-self: flex-start;
    }
`
