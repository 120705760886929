import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

import Logo from '../../assets/logo/logo-img.png'
import { Email, Lock } from '@material-ui/icons'
import { FiEye, FiEyeOff } from 'react-icons/fi'

import {
    Container,
    ConatainerText,
    InputContainer,
    InputEmail,
    InputPassword,
    Eyepassword,
    ButtonEntrar,
    TextDashboard
} from './styles';

const Signin: React.FC = () => {
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [show, setShow] = useState(false)

    const handleClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        setShow(!show);
    }

    const signInEmailAndPassword = () => {
        alert('Acessar')
    }

    const handleVoltarDashboard = () => {
        history.goBack()
    }

    return (
        <Container>
            <img src={Logo} alt="" onClick={handleVoltarDashboard} />
            <ConatainerText>
                <h1>Silvanei Martins</h1>
                <InputContainer>
                    <InputEmail>
                        <Email />
                        <input
                            placeholder="Digite seu e-mail"
                            value={email}
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </InputEmail>

                    <InputPassword>
                        <Lock />
                        <input
                            placeholder="Digite sua senha"
                            type={show ? "text" : "password"}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Eyepassword>
                            {show ? (
                                <FiEye
                                    size={20}
                                    onClick={handleClick}
                                />
                            ) : (
                                    <FiEyeOff
                                        size={20}
                                        onClick={handleClick}
                                    />
                                )}
                        </Eyepassword>
                    </InputPassword>

                    <ButtonEntrar
                        type='submit'
                        onClick={signInEmailAndPassword}
                    >
                        <h4>Acessar o Sistema</h4>
                    </ButtonEntrar>


                    <TextDashboard
                        onClick={handleVoltarDashboard}
                    >
                        <h4>Voltar pagina principal</h4>
                    </TextDashboard>
                </InputContainer>
            </ConatainerText>


        </Container>
    );
}

export default Signin;
