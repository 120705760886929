import React from "react";
import { Link } from "react-router-dom";

import Footer from "../Footer";

import SamBarber from "../../assets/projetos/samBarber.png";
import SamMecanica from "../../assets/projetos/samMecanica.png";
import ShareFile from "../../assets/projetos/shareFile.png";
import ListaTarefa from "../../assets/projetos/agendaTask.png";
import SamBellDelivery from "../../assets/projetos/sambellDelivery.png";
import ChatComercial from "../../assets/projetos/ChatComercial.png";

import {
    FaAngleDoubleRight,
    FaInternetExplorer,
    FaYoutubeSquare,
} from "react-icons/fa";

import {
    Container,
    ProjetoContainer,
    CardContainer,
    InfoContainer,
    Info,
    AcessaProjeto,
} from "./styles";

const ProjetosCard: React.FC = () => {
    return (
        <Container>
            {/* PROJETO AGENDAMENTO DE BARBEARIA */}
            <ProjetoContainer>
                <img src={SamBarber} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>SAMBARBER</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web para controlar o atendimento de uma
                            barbearia. Pode acompanhar quantas agendamento
                            existe e outras informações.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <a
                            href="http://sambarber.com.br/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acessar site do projeto
                        </a>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link to="/sambarberpage">
                            Mais informações sobre o projeto
                        </Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO SAMBELL DELIVERY */}
            <ProjetoContainer>
                <img src={SamBellDelivery} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>SAMBELL DEVILERY</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web para delivery, realizar venda online e
                            exposição dos projetos.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaYoutubeSquare size={18} />
                        <a
                            href="https://lnkd.in/eG_RPiW"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vídeo do Projeto SamBell
                        </a>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <a
                            href="https://vigilant-sinoussi-918924.netlify.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acessar site do projeto
                        </a>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link to="/sambell">
                            Mais informações sobre o projeto
                        </Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO CONTROLE MECANICO */}
            <ProjetoContainer>
                <img src={SamMecanica} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>SAM MECÂNICA </h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web para controlar o atendimento de uma
                            barbearia. Pode acompanhar quantas agendamento
                            existe e outras informações.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link to="/sammecanicapage">
                            Acessar site do projeto
                        </Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO COMPARTILHAMENTO DE AQUIVO */}
            <ProjetoContainer>
                <img src={ShareFile} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>SHARE FILE</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web para controlar o atendimento de uma
                            barbearia. Pode acompanhar quantas agendamento
                            existe e outras informações.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaYoutubeSquare size={18} />
                        <a
                            href="https://lnkd.in/eupjrW7"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vídeo do Projeto Share File
                        </a>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link to="/sharefilepage">Acessar site do projeto</Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO LISTA DE TAREFAS */}
            <ProjetoContainer>
                <img src={ListaTarefa} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>LISTA DE TAREFA</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web para realizar lista de tarefas uso no
                            dia a dia. No projeto simples você pode editar e
                            exclui as tarefas. Projeto ainda está em teste.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <a
                            href="https://peaceful-tesla-0ac393.netlify.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acessar site do projeto
                        </a>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                    {/* <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link
                        to='/sambarberpage'
                        >
                            Mais informações sobre o projeto
                        </Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto> */}
                </CardContainer>
            </ProjetoContainer>

            {/* PROJETO CHAT COMERCIAL */}
            <ProjetoContainer>
                <img src={ChatComercial} alt="" />
                <CardContainer>
                    <InfoContainer>
                        <h1>CHAT COMERCIAL</h1>
                    </InfoContainer>
                    <Info>
                        <p>
                            Software web chat comercial conexão na empresa. Pode
                            acompanhar quantas agendamento existe e outras
                            informações.
                        </p>
                    </Info>

                    <AcessaProjeto>
                        <FaInternetExplorer size={18} />
                        <Link to="/samchatpage">Acessar site do projeto</Link>
                        <FaAngleDoubleRight size={22} />
                    </AcessaProjeto>
                </CardContainer>
            </ProjetoContainer>

            <Footer />
        </Container>
    );
};

export default ProjetosCard;
