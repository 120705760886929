import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";

import logoSilvanei from "../../../assets/logo/logo-silvanei.svg";
import SamBarberImg from "../../../assets/projetos/projetosPages/samBarber.png";

import { Container, ContainerHeader, ConatainerText } from "./styles";

const SamBarber: React.FC = () => {
    return (
        <>
            <ContainerHeader>
                <Link to="/">
                    <MdKeyboardArrowLeft size={30} />
                    Voltar
                </Link>
                <h1>SAMBARBER</h1>
                <img src={logoSilvanei} alt="Silvanei Martins" />
            </ContainerHeader>
            <Container>
                <img src={SamBarberImg} alt="" />
                <ConatainerText>
                    <h1>Sam Barber</h1>
                    <h2>Pagina em Construção</h2>
                </ConatainerText>
            </Container>
        </>
    );
};

export default SamBarber;
