import React, {
    InputHTMLAttributes,
    useState,
    useCallback,
    useRef,
} from 'react'
import { IconBaseProps } from 'react-icons'
import { Container } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    icon: React.ComponentType<IconBaseProps>
}

const Input: React.FC<InputProps> = ({ icon: Icon, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isFilled, setIsFilled] = useState<boolean>(false)

    const handleInputBlur = useCallback(() => {
        setIsFocused(false)
        setIsFilled(!!inputRef.current?.value)
    }, [])

    const handleInputFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    return (
        <Container
            isFilled={isFilled}
            isFocused={isFocused}
        >
            {Icon && <Icon size={20} />}
            <input
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={inputRef}
                {...rest}
            />
        </Container>
    )
}

export default Input
