import styled from 'styled-components'
import { shade } from 'polished'

import Colors from '../../util/colors'

export const Container = styled.div`
    width: 60%;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;

    h1 {
        text-align: center;
        margin-bottom: 10px;
        color: ${Colors.secondary}
    }

    h3 {
        margin-top: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: ${Colors.secondary}
    }

    p {
        line-height: 1.7;
        color: ${Colors.secondary}
    }
`

export const ContainerVoltar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 100px;
    padding: 20px;

    > a {
        color: ${Colors.secondary};
        text-decoration: none;
        transition: color 0.2s;
        margin-right: 20px;
        font-size: 18px;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: ${shade(0.2, Colors.secondary)};
        }
    }

   svg {
       margin-top: 3px;
       margin-right: 5px;
   }
`
