import React from "react";
import { useHistory } from "react-router-dom";

import CardProjetos from "../../components/Card/CardProjetos";
// import Carousel from '../../components/Carousel/index.js';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Contats from "../../components/Contats";
// import CardsEstudos from '../../components/CardsEstudos'
// import CardsProjetos from '../../components/CardsProjetos'
import HobsPessoal from "../../components/HobsPessoal";
import Profile from "../../components/Profile";

import Bg from "../../assets/images/bg.png";

import {
    Container,
    SessaoContainer,
    CartContainer,
    TituloContainer,
    PerfilPessoal,
    Content,
    DescricaoContainer,
    ButtonContainer,
    ImgContainer,
    ContainerVideo,
    ReactPlayers,
} from "./styles";
import Acordion from "src/components/Acordion";

const Dashboard: React.FC = () => {
    const history = useHistory();

    const handleContato = () => {
        history.push("/sobredesenvolvedor");
    };

    return (
        <Container>
            <Header />

            <SessaoContainer>
                <TituloContainer>
                    <h1>
                        O estudo te leva
                        <br /> para o proximo nível<b>.</b>
                    </h1>
                </TituloContainer>
                <Content>
                    <DescricaoContainer>
                        <p>
                            Quer conhecer mais sobre mim,
                            <br />
                            informações dos meus projetos.
                        </p>
                        <ButtonContainer onClick={handleContato}>
                            ENTRAR EM CONTATO
                        </ButtonContainer>
                    </DescricaoContainer>
                </Content>

                <ContainerVideo>
                    <ReactPlayers
                        url="https://youtu.be/ngxoAXlAUEY"
                        controls={true}
                    />
                </ContainerVideo>

                <ImgContainer>
                    <img
                        src={Bg}
                        alt="Imagem de Desenvolvimento"
                    />
                    {/* <Carousel /> */}
                </ImgContainer>
            </SessaoContainer>

            <CartContainer>
                <h1>Conheça mais sobre nossos projetos</h1>
                <CardProjetos />
            </CartContainer>

            <PerfilPessoal>
                <h1>Perfil Pessoal</h1>
            </PerfilPessoal>

            <Profile />

            {/* <CardsProjetos />
            <CardsEstudos /> */}

            <HobsPessoal />
            <Contats />

            <Acordion />

            <Footer />
        </Container>
    );
};

export default Dashboard;
