import React from 'react';

import Constructor from '../../../assets/images/constructor.svg'

import {
    Container,
    ConatainerText
} from './styles';

const ShareFile: React.FC = () => {
    return (
        <Container>
            <img src={Constructor} alt=""/>
            <ConatainerText>
                <h1>ShareFile</h1>
                <h2>Pagina em Construção</h2>
            </ConatainerText>
        </Container>
    );
}

export default ShareFile;
