import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';

import logoSilvanei from '../../assets/logo/logo-silvanei.svg'
import Videos from '../../components/Videos'

import {
    Container,
    ContainerHeader,
    LogoContainer,
    Titulo
} from './styles'

const GaleriaVideo: React.FC = () => {
  return (
      <Container>
          <ContainerHeader>
                <Link to='/'>
                    <MdKeyboardArrowLeft size={27} />
                    Voltar
                </Link>

                <Titulo>
                    <h1>Galeria de Vídeo</h1>
                </Titulo>

                <LogoContainer>
                    <img src={logoSilvanei} alt="Silvanei Martins"/>
                </LogoContainer>
            </ContainerHeader>

            <Videos />
      </Container>
  );
}

export default GaleriaVideo;
