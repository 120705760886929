import styled from 'styled-components'
import { shade } from 'polished'
import Colors from '../../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    background: ${Colors.background};

    img {
        object-fit: contain;
        height: 400px;
    }
`

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;

    > a {
        color: ${Colors.green};
        text-decoration: none;
        transition: color 0.2s;
        font-size: 18px;
        margin-left: 50px;

        &:hover {
            color: ${shade(0.2, Colors.green)};
        }
    }

    svg {
        margin-bottom: -9px;
    }

    h1 {
        font-family: 'Roboto Slab', sans-serif;
        font-weight: 500;
        font-size: 40px;
        margin-left: 400px;
        color: ${Colors.placeholder};
    }

    img {
        display: flex;
        margin: 0 auto;
        margin-right: 5px;
    }
`
export const ConatainerText = styled.text`
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    h1 {
        font-size: 50px;
        color: ${Colors.green}
    }

    h2 {
        font-size: 35px;
        color: ${Colors.green}
    }
`
