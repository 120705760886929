import styled from 'styled-components'
import { shade } from 'polished'
import Colors from '../../util/colors'

export const Container = styled.div`
    width: 80%;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;

    margin-bottom: 50px;
`

export const ContainerVoltar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    a {
        color: ${Colors.secondary};
        text-decoration: none;
        transition: color 0.2s;
        margin-right: 20px;
        font-size: 18px;
        color: ${Colors.green};

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: ${shade(0.2, Colors.green)};
        }
    }

    svg {
        margin-top: 3px;
        margin-right: 5px;
    }
`

export const ImgProfile = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;

    background: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);

    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-left: 50px;
    }
`

export const InfoProfile = styled.div`
    align-items: center;
    justify-content: center;
    margin-left: 120px;

    h1 {
        font-size: 50px;
        text-align: center;
    }

    p {
        font-size: 20px;
        text-align: center;
    }
`

export const RedeSociais = styled.div`
    justify-content: center;
    border-radius: 4px;
    display: flex;
    width: 100%;
    background-color: ${Colors.shape};
    padding: 20px;

    background: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);

    list-style: outside none none;

    li {
        background: ${Colors.shape};
        width: 60px;
        height: 60px;
        border-radius: 5px;
        transition: all 0.2s ease;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            svg {
                width: 55px;
                height: 55px;
                border-radius: 3px;
            }
        }

        :hover{
            background: ${Colors.borders}
        }
    }
`

export const RedeSociaisTitle = styled.div`
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-bottom: 10px;

    h1 {
        text-align: center;
    }
`

export const InfoContato = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    background-color: ${Colors.shape};
    padding: 20px;

    background: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
`

export const EmailContato = styled.div`
    display: flex;
    padding: 10px;

    img {
        width: 30px;
        height: 30px;
    }

    h3 {
        margin-left: 10px;
    }

    &:hover {
        color: ${shade(0.2, Colors.white)};
    }
`

export const WhatsappContato = styled.div`
    display: flex;
    padding: 10px;

    img {
        width: 30px;
        height: 30px;
    }

    h3 {
        margin-left: 10px;
    }

    &:hover {
        color: ${shade(0.2, Colors.white)};
    }
`

export const YoutubeContato = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;

    img {
        width: 30px;
        height: 30px;
    }

    a {
        margin-left: 10px;
        color: ${Colors.white};
        text-decoration: none;
    }

    &:hover {
        color: ${shade(0.2, Colors.white)};
    }
`

export const InfoPessoal = styled.div`
    display: flex;
    border-radius: 8px;
    width: 100%;
    background-color: ${Colors.shape};
    padding: 20px;

    background: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);

    img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
    }
`

export const InfoPessoalText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;

    h3 {
        margin-left: 20px;
        margin-top: 10px;
        font-weight: 300;

        a {
            margin-left: 10px;
            color: ${Colors.green};
            text-decoration: none;
            font-weight:  500;

            &:hover {
                color: ${shade(0.2, Colors.green)};
            }
        }
    }

`
