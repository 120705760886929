import React from "react";

import Footer from "../../components/Footer";
import { Container, ContainerVideo, ReactPlayers } from "./styles";

const Videos: React.FC = () => {
    return (
        <Container>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/ngxoAXlAUEY"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/gKvrOEWcH-Y"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/CxcCiITDRQ0"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/K3t6FDAtFRA"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/P1Qy5OV7A88"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/mplH9obhAeg"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/DoKcLi-TYxE"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/X-xHn_tNQbE"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/ZSdjr4QsrEI"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/i4Ay2jHx3HU"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/6oNO7ErzTwo"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/m1ETxSdUVRY"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/dFral7kUYNk"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/BNtMV03DUQE"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/4K9ejx-9Qmo"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/l5WcNVy_Vwk"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/cxtNlEk5de4"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/b7xtoPXm3Ns"
                    controls={true}
                />
            </ContainerVideo>
            <ContainerVideo>
                <ReactPlayers
                    url="https://youtu.be/-GkbS2LJRAs"
                    controls={true}
                />

                <ReactPlayers
                    url="https://youtu.be/7O8vnqIMLCI"
                    controls={true}
                />
            </ContainerVideo>

            <Footer />
        </Container>
    );
};

export default Videos;
