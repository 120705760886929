import styled from 'styled-components'

import Colors from '../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const ProjetoContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    background: ${Colors.shape};
    border-radius: 8px;
    padding: 10px !important;
    max-width: 1120px;
    background: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);

    img {
        width: 550px;
        height: 350px;
        padding: 20px;
    }
`

export const CardContainer = styled.div`
    margin-left: 50px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
`

export const InfoContainer = styled.text`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    h1 {
        font-size: 25px;
        color: ${Colors.green};
    }

    h2 {
        font-size: 30px;
        margin-left: 10px;
    }
`

export const Info = styled.text`
    display: flex;
    width: 100%;
    flex-direction: column;

    h1 {
        color: ${Colors.red};
        font-size: 40px;
    }

    p {
        margin-top: 30px;
        text-align: left;
    }
`

export const AcessaProjeto = styled.div`
    margin-top: 30px;
    display: flex;
    cursor: pointer;

    a {
        font-size: 20px;
        color: ${Colors.green};
        margin-left: 5px;
        text-decoration: none;
    }

    svg {
        color: ${Colors.green};
        margin-top: 2px;
    }
`

