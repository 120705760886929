import React from 'react'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'

import PortfolioCard from '../../components/PortfolioCard'
import logoSilvanei from '../../assets/logo/logo-silvanei.svg'


import {
    Container,
    ContainerHeader,
    LogoContainer,
    Titulo
} from './styles'

const Portfolio: React.FC = () => {
    return (
        <Container>
            <ContainerHeader>
                <Link to='/'>
                    <MdKeyboardArrowLeft size={27} />
                    Voltar
                </Link>

                <Titulo>
                    <h1>Portfólio</h1>
                </Titulo>

                <LogoContainer>
                    <img src={logoSilvanei} alt="Silvanei Martins"/>
                </LogoContainer>
            </ContainerHeader>


            <PortfolioCard />
        </Container>
    )
}

export default Portfolio
