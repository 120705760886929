import React from 'react'

import Silvanei from '../../assets/images/bg-silvanei-1.jpeg'
import Perfil from '../../assets/images/profile.svg'

import {
    Container,
    InfoImage,
    DashboardInfoPessoal,
    InfoPessoal,
    Info
} from './styles'

const Profile: React.FC = () => {
    return (
        <Container>
            <img src={Perfil} alt="Silvanei Martins" />
            <DashboardInfoPessoal>
                <InfoImage>
                    <img src={Silvanei} alt="Silvanei Martins" />
                </InfoImage>
                <InfoPessoal>
                    <h1>Desenvolvedor: </h1>
                    <h2>Silvanei Martins</h2>
                </InfoPessoal>
                <Info>
                    <h1>Desenvolvedor Full Stack</h1>
                    <p>Desenvolvimento de sites personalizados, apps mobile e sistemas para
                        web personalizados conforme a necessidade.</p>
                </Info>
            </DashboardInfoPessoal>
        </Container>
    )
}

export default Profile;
