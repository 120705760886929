import styled from 'styled-components'

import Colors from '../../util/colors'

export const Hobs = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    h1 {
        font-size: 40px;
        line-height: 119%;
        font-weight: bold;
        color: ${Colors.green};
    }
`

export const Container = styled.div`
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 50px 50px;
`

export const ContentCursos = styled.div`
    width: 100%;
    height: 100%;
    max-width: 256px;
    border-radius: 5px;
    max-width: 100%;
    background-color: ${Colors.shapePrice};
    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
`

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    position: relative;
    border-bottom: 1px solid ${Colors.borders};

    strong {
        font-weight: bold;
    }

    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: ${Colors.icons};
    }
`

export const Corpo = styled.div`
    padding: 20px;
    display: block;

    h3 {
        font-size: 32px;
        line-height: 119%;
        font-weight: bold;
        color: ${Colors.green};
    }

    img {
        margin-left: 60px;
        width: 100px;
        height: 100px;
    }

    h4 {
        font-weight: 500;
        font-size: 24px;
        line-height: 125%;
        color: ${Colors.green};
        padding: 24px 0;
    }

    p {
        font-size: 18px;
        line-height: 156%;
        color: ${Colors.text};
    }
`

