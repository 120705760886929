import React, { ButtonHTMLAttributes } from 'react'

import { FiArrowRight } from 'react-icons/fi'
import { Container, IconButton } from './styles'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
    return (
        <Container type='button' {...rest}>
            {children}
            <IconButton>
                <FiArrowRight
                    size={20}
                />
            </IconButton>
        </Container>
    )
}

export default Button
