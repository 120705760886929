import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
// import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
    Container,
    CardProjeto001,
    CardProjeto002,
    CardProjeto003,
    CardProjeto004,
} from "./styles";

import SamBellDelivery from "../../../assets/projetos/sambellDelivery.png";
import SamBarber from "../../../assets/projetos/samBarber.png";
import ShareFile from "../../../assets/projetos/shareFile.png";
import ChatComercial from "../../../assets/projetos/ChatComercial.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 330,
        },
        media: {
            height: 0,
            paddingTop: "56.25%",
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
    })
);

const CardProjetos: React.FC = () => {
    const history = useHistory();

    const classes = useStyles();
    const [expanded001, setExpanded001] = useState(false);
    const [expanded002, setExpanded002] = useState(false);
    const [expanded003, setExpanded003] = useState(false);
    const [expanded004, setExpanded004] = useState(false);

    const handleExpandClick001 = () => {
        setExpanded001(!expanded001);
        history.push("/sambell");
    };

    const handleExpandClick002 = () => {
        setExpanded002(!expanded002);
        history.push("/sambarberpage");
    };

    const handleExpandClick003 = () => {
        setExpanded003(!expanded003);
        history.push("/sharefilepage");
    };

    const handleExpandClick004 = () => {
        setExpanded004(!expanded004);
        history.push("/samchatpage");
    };

    return (
        <Container>
            <CardProjeto001>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                            >
                                SB
                            </Avatar>
                        }
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title="SamBell Delivery"
                        subheader="conheça mais sobre o projeto"
                    />
                    <CardMedia
                        className={classes.media}
                        image={SamBellDelivery}
                        title="SamBell Delivery"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Software web para delivery, podendo cadastrar seus
                            produtos, realizar venda e vitrine para expor outros produtos. Cliente logado pode acompanhar sua entrega.
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        {/* <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded001,
                            })}
                            onClick={handleExpandClick001}
                            aria-expanded={expanded001}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton> */}
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded001,
                            })}
                            onClick={handleExpandClick001}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    {/* <Collapse in={expanded001} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Method:</Typography>
                            <Typography paragraph>
                                Heat 1/2 cup of the broth in a pot until
                                simmering, add saffron and set aside for 10
                                minutes.
                            </Typography>
                            <Typography paragraph>
                                Heat oil in a (14- to 16-inch) paella pan or a
                                large, deep skillet over medium-high heat. Add
                                chicken, shrimp and chorizo, and cook, stirring
                                occasionally until lightly browned, 6 to 8
                                minutes.
                            </Typography>
                        </CardContent>
                    </Collapse> */}
                </Card>
            </CardProjeto001>

            <CardProjeto002>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                            >
                                R
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="SamBarber"
                        subheader="Conheça mais sobre o projeto"
                    />
                    <CardMedia
                        className={classes.media}
                        image={SamBarber}
                        title="SamBarber"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Software web e mobile para agendamento de barbearia, podendo cadastrar os barbeiro e serviços, Clienda pode agenda um serviço como pode cancelar  o serviço.
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        {/* <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded002,
                            })}
                            onClick={handleExpandClick002}
                            aria-expanded={expanded002}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton> */}
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded002,
                                })}
                                onClick={handleExpandClick002}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                    </CardActions>
                    {/* <Collapse in={expanded002} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Method:</Typography>
                            <Typography paragraph>
                                Heat 1/2 cup of the broth in a pot until
                                simmering, add saffron and set aside for 10
                                minutes.
                            </Typography>
                            <Typography paragraph>
                                Heat oil in a (14- to 16-inch) paella pan or a
                                large, deep skillet over medium-high heat. Add
                                chicken, shrimp and chorizo, and cook, stirring
                                occasionally until lightly browned, 6 to 8
                                minutes.
                            </Typography>
                        </CardContent>
                    </Collapse> */}
                </Card>
            </CardProjeto002>

            <CardProjeto003>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                            >
                                R
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Share File"
                        subheader="Conheça mais sobre o projeto"
                    />
                    <CardMedia
                        className={classes.media}
                        image={ShareFile}
                        title="Share File"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Software web para compartilhamento de documentos, podendo cadastrar documentos PDF, WORD e EXCEL para ser acessado pelos cliente de suas empresa.
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        {/* <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded003,
                            })}
                            onClick={handleExpandClick003}
                            aria-expanded={expanded003}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton> */}
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded003,
                            })}
                            onClick={handleExpandClick003}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    {/* <Collapse in={expanded003} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Method:</Typography>
                            <Typography paragraph>
                                Heat 1/2 cup of the broth in a pot until
                                simmering, add saffron and set aside for 10
                                minutes.
                            </Typography>
                            <Typography paragraph>
                                Heat oil in a (14- to 16-inch) paella pan or a
                                large, deep skillet over medium-high heat. Add
                                chicken, shrimp and chorizo, and cook, stirring
                                occasionally until lightly browned, 6 to 8
                                minutes.
                            </Typography>
                        </CardContent>
                    </Collapse> */}
                </Card>
            </CardProjeto003>

            <CardProjeto004>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                            >
                                R
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Chat Comercial"
                        subheader="Conheça mais sobre o projeto"
                    />
                    <CardMedia
                        className={classes.media}
                        image={ChatComercial}
                        title="Chat Comercial"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Software web para conversa e compartilhar de informações empresarial dentro da empresa, podendo assim ter um bom relacionamento de trabalho entre os colaboradores.
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        {/* <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded004,
                            })}
                            onClick={handleExpandClick004}
                            aria-expanded={expanded004}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton> */}
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded004,
                            })}
                            onClick={handleExpandClick004}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    {/* <Collapse in={expanded004} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Method:</Typography>
                            <Typography paragraph>
                                Heat 1/2 cup of the broth in a pot until
                                simmering, add saffron and set aside for 10
                                minutes.
                            </Typography>
                            <Typography paragraph>
                                Heat oil in a (14- to 16-inch) paella pan or a
                                large, deep skillet over medium-high heat. Add
                                chicken, shrimp and chorizo, and cook, stirring
                                occasionally until lightly browned, 6 to 8
                                minutes.
                            </Typography>
                        </CardContent>
                    </Collapse> */}
                </Card>
            </CardProjeto004>
        </Container>
    );
};

export default CardProjetos;
