import React from 'react'
import { Link } from 'react-router-dom'

import { MdKeyboardArrowLeft } from 'react-icons/md'
import ProjetosCard from '../../components/ProjetosCard'
import logoSilvanei from '../../assets/logo/logo-silvanei.svg'

import {
    Container,
    ContainerHeader,
    HeaderProjetos,
    LogoContainer,
    Titulo,
} from './styles'

const Projetos: React.FC = () => {
    return (
        <Container>
            <ContainerHeader>
                <HeaderProjetos>
                    <Link to='/'>
                        <MdKeyboardArrowLeft size={27} />
                        Voltar
                    </Link>

                    <Titulo>
                        <h1>Projetos</h1>
                    </Titulo>

                    <LogoContainer>
                        <img src={logoSilvanei} alt="Silvanei Martins" />
                    </LogoContainer>
                </HeaderProjetos>
            </ContainerHeader>


            <ProjetosCard />
        </Container>
    )
}

export default Projetos
