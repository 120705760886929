import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { MdWork, MdSubtitles, MdLock, MdVideoLibrary, MdSchool } from 'react-icons/md'
import logoSilvanei from '../../assets/logo/logo-silvanei.svg'

import {
    Container,
    LogoContainer,
    PagesProjetos,
    ButtonEntrar
} from './styles'

const Header: React.FC = () => {
    const history = useHistory()

    const handleLogin = () => {
        history.push('/signin')
    }

    return (
        <Container>

            <LogoContainer>
                <img src={logoSilvanei} alt="Silvanei Martins"/>
            </LogoContainer>

            <PagesProjetos>
                <Link
                    to='/galeriavideo'
                >
                    <MdVideoLibrary size={20} />
                    Galeria Vídeo
                </Link>
                <Link
                    to='/portfolio'
                >
                    <MdSubtitles size={20} />
                    Portfólio
                </Link>
                <Link
                    to='/projetos'
                >
                    <MdWork size={20} />
                    Projetos
                </Link>
                <Link
                    to='/signincursos'
                >
                    <MdSchool size={20} />
                    Cursos
                </Link>
            </PagesProjetos>

            <ButtonEntrar
                type='submit'
                onClick={handleLogin}
            >
                <MdLock size={20} />
                Acessar
            </ButtonEntrar>

        </Container>
    )
}

export default Header
