import React, { useState } from 'react'

import Input from './Input'
import TextArea from './TextArea'
import Button from './Button'

import {
    FiUser,
    FiMail,
    FiMessageSquare
} from 'react-icons/fi';

import {
    Container,
    ContatoTitle,
    Content,
    ContentInfo,
    ContentContato
} from './styles'

const Contats: React.FC = () => {
    const [send, setSend] = useState('Enviar Mensagem')

    const sendEmail = () => {
        setTimeout(() => {
            setSend('Mensagem enviado com sucesso...');
        }, 1000);
        return setSend('Enviando Mgs...');
    }

    return (
        <Container>

            <ContatoTitle>
                <h1>Contato</h1>
            </ContatoTitle>

            <Content>
                <ContentInfo>
                    <h2>Quer conversar comigo?</h2>
                    <p>
                        Caso tenha se interessado pelo meu trabalho, preencha o formulário
                        que em breve conversaremos. <br /> <br />
                        Se preferir, pode me enviar um e-mail{' '}

                        <a href="mailto:silvaneimartins_rcc@hotmail.com">
                            silvaneimartins_rcc@hotmail.com
                        </a>
                    </p>
                </ContentInfo>

                <form
                    action="https://postmail.invotes.com/send"
                    method="post"
                    id="email_form"
                    onSubmit={sendEmail}
                >
                    <ContentContato>
                        <Input
                            autoComplete="nope"
                            placeholder="Digíte seu nome para contato."
                            type="text"
                            name="name"
                            id="name"
                            icon={FiUser}
                            required
                        />

                        <Input
                            autoComplete="off"
                            placeholder="Digíte seu e-mail para contato."
                            type="email"
                            name="email"
                            id="email"
                            icon={FiMail}
                            required
                        />

                        <TextArea
                            placeholder="Escreva sua mensagem."
                            name="message"
                            id="message"
                            icon={FiMessageSquare}
                            required
                        />

                        <input type="hidden" name="access_token" value="n2dvf1mbautv69qs9kkzz707" />
                        <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" />
                        <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" />

                        <Button id="submit_form" type="submit" value="Send">{send}</Button>
                    </ContentContato>
                </form>
            </Content>

        </Container>
    )
}

export default Contats
