import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from '../src/routes'
import GlobalStyles from '../src/styles/globalstyles'

const App: React.FC = () => {
    return (
        <Router>
            <Routes />
            <GlobalStyles />
        </Router>
    );
}

export default App;
