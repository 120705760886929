import React from "react";

import Constructor from "../../../assets/images/constructor.svg";

import { Container, ConatainerText } from "./styles";

const SamChat: React.FC = () => {
    return (
        <Container>
            <img src={Constructor} alt="" />
            <ConatainerText>
                <h1>SamChat</h1>
                <h2>Pagina em Construção</h2>
            </ConatainerText>
        </Container>
    );
};

export default SamChat;
