import styled from 'styled-components';
import { shade } from 'polished'
import Colors from '../../util/colors';

export const Container = styled.div`
    width: 100%;
    margin: 50px auto;
    padding: 50px;
    border-radius: 3px;

    h1 {
        margin-left: 35%;
        margin-bottom: 50px;
        align-items: center;
    }

    p {
        display: flex;
        align-items: center;
    }

    a {
        color: ${Colors.secondary};
        text-decoration: none;
        transition: color 0.2s;
        margin-left: 10px;
        font-size: 18px;
        color: ${Colors.green};

        display: flex;
        align-items: center;
        /* justify-content: center; */

        &:hover {
            color: ${shade(0.2, Colors.green)};
        }
    }
`;
