import { createGlobalStyle } from 'styled-components'

import Colors from '../util/colors'

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        background: ${Colors.shape};
        color: ${Colors.white};
        -webkit-font-smoothing: antialiased;
    }

    body, input, button {
        font-family: 'Roboto Slab', sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    h3 {
        cursor: pointer;
    }

    button, text {
        // cursor: pointer;
        border: none;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #201c29;
        border-radius: 5px;
        box-shadow: inset 1px 1px 1px hsla(0,0%,100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
    }

    ::-webkit-scrollbar-track {
        background: linear-gradient(90deg,#201c29,#201c29 1px,#17141d 0,#17141d);
    }

    :root{
        --color-background: #121214;
        --color-green: #04d361;
        --color-red: #e83f5b;
        --color-orange: #fd951f;
        --color-yellow: #f7df1e;
        --color-primary: #8257e6;
        --color-primary-hover: #9466ff;
        --color-secondary: #e1e1e6;
        --color-gray: #808080;
        --color-text: #a8a8b3;
        --color-support: #737380;
        --color-shape: #202024;
        --color-shape-hover: #29292e;
        --color-icons: #41414d;
        --color-borders: #323238;
        --color-black: #0d0d0f;
    }

`
