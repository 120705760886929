import styled from 'styled-components'
import { shade } from 'polished'

import Colors from '../../../util/colors'

export const Container = styled.button`
    display: flex;
    background: ${Colors.green};
    height: 45px;
    border-radius: 4px;
    border: 0px;
    width: 100%;
    color: ${Colors.black};
    font-weight: 500;
    transition: background-color 0.2s;
    font-size: 18px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;

   cursor: pointer;

   &:hover {
      background: ${shade(0.2, Colors.green)};
   }


`

export const IconButton = styled.div`
    svg {
        margin-left: 20px;
        margin-top: 7px;
   }
`

