import React from 'react';

import Constructor from '../../../assets/images/constructor.svg'

import {
    Container,
    ConatainerText
} from './styles';

const SamMecanica: React.FC = () => {
    return (
        <Container>
            <img src={Constructor} alt=""/>
            <ConatainerText>
                <h1>Estamos em</h1>
                <h2>Construção</h2>
            </ConatainerText>
        </Container>
    );
}

export default SamMecanica;
