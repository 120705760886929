import styled from 'styled-components'
import { shade } from 'polished'
import Colors from '../../util/colors'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: ${Colors.shape};

    img {
        width: 400px;
        height: 400px;
        margin-top: 60px;

        &:hover {
            cursor: pointer;
        }
    }

    h1 {
        font-family: 'Roboto Slab';
        font-weight: 500;
        font-size: 50px;
        margin-bottom: 10px;
        color: #e1e1e6;
    }
`

export const ConatainerText = styled.text`
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 50px;
        color: ${Colors.green}
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${Colors.secondary};
    background-color: ${Colors.shapePrice};
    padding: 20px 20px;
    width: 130%;
    height: 450px;
    border-radius: 5px;

    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.7);
`

export const InputEmail = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    background-color: ${Colors.shape};
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.7);

    input {
        background: transparent;
        width: 100%;
        outline-width: 0;
        color: #E1E1E6;
        border: none;
        font-size: 17px;
        margin-left: 10px;
        margin-right: 10px;
    }

    svg {
        margin-left: 10px;
    }
`

export const InputPassword = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    background-color: ${Colors.shape};
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.7);

    input {
        background: transparent;
        width: 100%;
        outline-width: 0;
        color: #E1E1E6;
        border: none;
        font-size: 17px;
        margin-left: 10px;
        margin-right: 10px;
    }

    svg {
        margin-left: 10px;
    }
`

export const Eyepassword = styled.div`
    margin-right: 15px;
    cursor: pointer;
`

export const ButtonEntrar = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 98%;
    background-color: ${Colors.backgroundHover};
    color: ${Colors.secondary};
    font-weight: 800;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.2);

    svg {
        margin-right: 10px;
    }

    &:hover {
        background-color: ${Colors.background};
        box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.2);
        color: #EFF2F5;
    }
`

export const TextDashboard = styled.text`
    display: flex;
    margin-top: 30px;
    cursor: pointer;

    svg {
        margin-right: 10px;
    }

    h4 {
        color: ${Colors.green}
    }

    h4:hover {
      color: ${shade(0.2, Colors.green)};
   }
`
