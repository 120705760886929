import styled from 'styled-components'
import { shade } from 'polished'

import Colors from '../../util/colors'

export const Container = styled.div`
    /* position: sticky;
    top: 0;
    left: 0;
    right: 0; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 10px;

    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5);
`

export const PagesProjetos = styled.div`
    display: flex;
    margin: 0 auto;
    margin-right: 5px;

    > a {
      color: ${Colors.green};
      text-decoration: none;
      transition: color 0.2s;
      margin-right: 20px;
      font-size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
         color: ${shade(0.2, Colors.green)};
      }
   }

   svg {
       margin-right: 5px;
   }
`

export const ButtonEntrar = styled.button`
    font-family: "Roboto Slab", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    justify-content: center;
    text-transform: uppercase;
    background-color: ${Colors.green};
    color: ${Colors.shape};
    border-radius: 3px;
    border: none;
    padding: 7px;
    width: 120px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    margin-right: 40px;

    box-shadow: 7px 7px 7px -7px rgba(0, 0, 0, 0.8);

    svg {
        margin-right: 5px;
    }

    &:hover {
        background-color: ${shade(0.2, Colors.greenDark)};
    }
`


export const LogoContainer = styled.div`
    svg {
        width: 100%;
        margin-left: 60px;
    }

    /* img {
        width: 300px;
        height: 100px;
    } */
`
