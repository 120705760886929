import styled from 'styled-components'
import { shade } from 'polished'

import Colors from '../../util/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 30px;
    margin-left: 70px;
`

export const HeaderProjetos = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-left: 70px;

    > a {
        color: ${Colors.green};
        text-decoration: none;
        transition: color 0.2s;
        margin-right: 20px;
        font-size: 18px;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: ${shade(0.2, Colors.green)};
        }
    }

   svg {
       margin-top: 3px;
   }
`

export const Titulo = styled.div`
    font-family: 'Roboto Slab', sans-serif;
    font-weight: 500;
    display: flex;
    margin: 0 auto;
    margin-right: 50px;

    h1 {
        font-size: 40px;
        color: ${Colors.placeholder};
    }
`

export const LogoContainer = styled.div`
    display: flex;
    margin: 0 auto;
    margin-right: 5px;
`

