import React, {
    TextareaHTMLAttributes,
    useState,
    useCallback,
    useRef,
} from 'react'
import { IconBaseProps } from 'react-icons'
import { Container } from './styles'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string
    icon: React.ComponentType<IconBaseProps>
}

const TextArea: React.FC<TextAreaProps> = ({ icon: Icon, ...rest }) => {
    const inputRef = useRef<HTMLTextAreaElement>(null)

    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isFilled, setIsFilled] = useState<boolean>(false)

    const handleInputBlur = useCallback(() => {
        setIsFocused(false)
        setIsFilled(!!inputRef.current?.value)
    }, [])

    return (
        <Container isFilled={isFilled} isFocused={isFocused}>
            {Icon && <Icon size={20} />}
            <textarea
                onFocus={() => setIsFocused(true)}
                onBlur={handleInputBlur}
                ref={inputRef}
                {...rest}
            />
        </Container>
    )
}

export default TextArea
