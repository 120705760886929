import React from "react";

import { Container } from "./styles";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Acordion: React.FC = () => {
    return (
        <Container>
            <h1>Pergunta e Respostas</h1>

            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            O por que escolheu trabalhar com programação?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            A 4 anos atrás, me deparei com alguns arquivos com
                            um monte de escrita estranha que mais para frente
                            descobrir que era java, meio que sem entender o que
                            realmente está escrito fui procurar entender, fui
                            procurar tutorial na internet. <br /> <br />
                            Foi paixão a primeira vista quando consegui enteder
                            simplesmente alguns arquivo.
                            <br /> <br /> Procurei curso para estudar e tentar
                            entrar na area de progração, antes de entrar de
                            cabeça de verdade nos estudo, procurei linguagem de
                            programação, quais estava em alta foi que ai cheguei
                            no javascript e react. Hoje posso dizer que sou
                            apaixonado pelo que estou estudando.
                            <br /> <br />
                            Ainda não trabalho na área, mas tenho fé que em
                            breve estarei trabalhando.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Por que a tecnologia javascript, react e programação
                            híbrida?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            É mais rápido que outras linguagens de programação,
                            não consume recursos do servidor, tem diversas
                            bibliotecas, fornece mais interatividades com os
                            sites, não precisa de compilador e existe muitas
                            outras vantagens em usar o javascript, o por que da
                            escolha de utilizar react, como já referido, sou um
                            fã da reutilização de componentes, o que significa
                            que levo em consideração a reutilização extensiva de
                            código, podendo deixar o seu codigo mais limpo e
                            organizado. <br /> <br /> A forma híbrida se utiliza
                            de linguagens mais comuns para facilitar a
                            compatibilidade entre diferentes sistemas
                            operacionais e a funcinabilidade nos diversos
                            aparelhos. <br /> <br /> A partir disso, ele simula
                            as interfaces de vários sistemas operacionais
                            integrando as funcionalidades do dispositivo nativo,
                            melhorando a experiência do usuário com seu
                            aparelho. Nestas poucas linhas descrevo pensamentos
                            que me motivou as escolhas referidas.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            O que é javascript?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            JavaScript é uma linguagem de programação que
                            permite a você implementar itens complexos em
                            páginas web — toda vez que uma página da web faz
                            mais do que simplesmente mostrar a você informação
                            estática — mostrando conteúdo que se atualiza em um
                            intervalo de tempo, mapas interativos ou gráficos
                            2D/3D animados, etc. — você pode apostar que o
                            JavaScript provavelmente está envolvido. É a
                            terceira camada do bolo das tecnologias padrões da
                            web, duas das quais são (HTML e CSS). <br />
                            <br />
                            HTML é a linguagem de marcação que nós usamos para
                            estruturar e dar significado para o nosso conteúdo
                            web. Por exemplo, definindo parágrafos, cabeçalhos,
                            tabelas de conteúdo, ou inserindo imagens e vídeos
                            na página. <br />
                            <br />
                            CSS é uma linguagem de regras de estilo que nós
                            usamos para aplicar estilo ao nosso conteúdo HTML.
                            Por exemplo, definindo cores de fundo e fontes, e
                            posicionando nosso conteúdo em múltiplas colunas.{" "}
                            <br /> <br />
                            JavaScript é uma linguagem de programação que
                            permite a você criar conteúdo que se atualiza
                            dinamicamente, controlar múltimídias, imagens
                            animadas, e tudo o mais que há de intessante. Ok,
                            não tudo, mas é maravilhoso o que você pode efetuar
                            com algumas linhas de código JavaScript.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            O que é react?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            O desenvolvimento de sistemas e aplicativos está em
                            constante evolução. foi em um desses momento,
                            milhares de desenvolvedores estão criando seus
                            frameworks para auxiliar nos desenvolvimentos,
                            bibliotecas e ferramentas com o objetivo de ajudar
                            outros desenvolvedores a atingir o máximo de
                            eficiência possível na produtividade.
                            <br /> <br />
                            Muitas vezes, essas tecnologias são concebidas
                            dentro de grandes empresas, como projetos paralelos
                            que buscam resolver gargalos internos das equipes
                            técnicas.
                            <br /> <br />
                            Algumas se tornam soluções tão interessantes que as
                            companhias decidem compartilhá-las com o mundo.
                            <br /> <br />
                            O React é um das biblioteca em questão.
                            <br />
                            <br />
                            Como definido por seus criadores, React é “uma
                            biblioteca JavaScript declarativa, eficiente e
                            flexível para a criação de interfaces de usuário
                            (UI)”.
                            <br />
                            Essa biblioteca surgiu em 2011, no Facebook, e
                            passou a ser utilizada na interface do mural de
                            notícias da rede social.
                            <br /> <br />
                            No ano seguinte, passou a integrar também a área de
                            tecnologia do Instagram e de várias outras
                            ferramentas da empresa. Em 2013, o código foi aberto
                            para a comunidade, o que colaborou para sua grande
                            popularização.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            O que é react.js?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            O React.js é assim uma biblioteca de JavaScript
                            flexível e eficiente para a criação de UI mais
                            específicos web, e é a mais procurada no mercado de
                            trabalho no ano de 2018, bem como uma das frameworks
                            de JavaScript mais populares atualmente no mercado
                            de trabalho. <br /> <br />
                            Atingiu inclusivamente um pico em junho de 2018,
                            tendo sido mencionado em cerca de 28% das ofertas de
                            emprego por entre as línguas mais populares. A
                            lacuna entre o Angular e o React.js tornou-se ainda
                            maior, com um declínio contínuo da popularidade do
                            Angular.
                            <br /> <br />
                            Assim se conclui que vale a pena aprender React.js,
                            pois torna as aplicações em mais eficientes e
                            divertidas. Considerando todos os itens acima,
                            podemos afirmar que este ano é considerado o ano do
                            React.js como a melhor framework de JavaScript
                            disponível no mercado.
                            <br /> <br />
                            Top Websites criados a usar React.js <br />
                            Facebook, Uber, Instagram, WhatsApp, Khan Academy,
                            Airbnb, Dropbox, Flipboard, Netflix, PayPal.
                            <br /> <br />
                            São só algumas das startup desenvolvida em react.js
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            O que é react native?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            O React Native é um framework baseado no já aclamado
                            React, desenvolvido e criado pela equipe do Facebook
                            sobre a licença MIT, que possibilita o
                            desenvolvimento de aplicações mobile, tanto para
                            Android, como para iOS, utilizando apenas
                            Javascript. <br /> <br />
                            Olhando por esse lado, não parece ser nada
                            surpreendente, nem inovador, pois já existem
                            inúmeros frameworks que empacotam aplicações web em
                            uma espécie de “browser”, como o Cordova, ou o
                            Manifold.js, porém o React Native é diferente, pois
                            todo o código desenvolvido é convertido para a
                            linguagem nativa do sistema operacional.
                            <br /> <br />
                            Um Framework é um facilitador no desenvolvimento de
                            diversas aplicações e, sem dúvida, sua utilização
                            poupa tempo e custos para quem utiliza, pois de
                            forma mais básica, é um conjunto de bibliotecas
                            utilizadas para criar uma base, onde as aplicações
                            são construídas, um otimizador de recursos.
                            <br /> <br />
                            Baseado no React, framework JS para desenvolvimento
                            web, o React Native possibilita a criação de
                            aplicações móvel multiplataforma (Android e iOS)
                            utilizando apenas Javascript. Porém, diferente de
                            outros frameworks com esta mesma finalidade
                            (Cordova, por exemplo), todo o código desenvolvido
                            com o React Native é convertido para linguagem
                            nativa do sistema operacional, o que torna o app
                            muito mais fluido.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Quais suas redes sociais profissional?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Rede social profissional{" "}
                            <a
                                href="https://www.linkedin.com/in/silvanei-martins-a5412436/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Linkedin
                            </a>
                        </p>

                        <p>
                            Rede social profissional{" "}
                            <a
                                href="https://github.com/SilvaneiMartins"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Github
                            </a>
                        </p>

                        <p>
                            Rede social profissional{" "}
                            <a
                                href="https://twitter.com/SilvaneiMartins"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Twitter
                            </a>
                        </p>

                        <p>
                            Contato profissional{" "}
                            <a
                                href="https://t.me/silvaneimartins"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Telegram
                            </a>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </Container>
    );
};

export default Acordion;
