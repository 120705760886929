import styled from 'styled-components';
import Colors from '../../../util/colors';

export const Container = styled.div`
    display: flex;
    margin-bottom: 50px;
`;

export const CardProjeto001 = styled.div`
    margin-right: 5px;
    color: ${Colors.white};
`;

export const CardProjeto002 = styled.div`
    margin-right: 5px;
    color: ${Colors.white};
`;

export const CardProjeto003 = styled.div`
    margin-right: 5px;
    color: ${Colors.white};
`;

export const CardProjeto004 = styled.div`
    color: ${Colors.white};
`;

